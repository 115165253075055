import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import whiteHex from '../../../static/img/white-hex.svg';

const StyledCard = styled.div`
	background-image: url(${whiteHex});
	background-size: auto 100%;
	background-position: 80% center;
	background-repeat: no-repeat;
	padding: 2rem 0;

	@media (max-width: 768px) {
		padding: 3rem 1.5rem 0;
		margin-right: -1.5rem;
		margin-bottom: 3rem;
		margin-left: -1.5rem;
		background-position: 80% top;
	}

	a {
		color: ${(p) => p.theme.color.carbon};

		&:hover {
			color: ${(p) => p.theme.color.grey};
		}

		.image-container {
			padding-right: 2rem;

			@media (max-width: 768px) {
				padding-right: 0;
			}
		}

		.details-container {
			padding: 3rem 0;

			@media (max-width: 768px) {
				padding: 0 0 3rem;
			}

			h4 {
				font-size: 1.5625rem;
				letter-spacing: 0.02em;
				font-weight: 800;
				margin-bottom: 1em;
			}

			h5 {
				font-size: 1.125rem;
				font-weight: 600;
				margin-bottom: 0.5em;
			}

			p {
				font-size: 1.125rem;
				margin-bottom: 0.5em;
			}
		}
	}
`;

const ProjectGalleryCard = ({ project }) => (
	<StyledCard>
		<Link to={project.fields.slug}>
			<div className="columns">
				<div className="column">
					<div className="image-container">
						<img src={project.galleryImage} alt="EK Construction" />
					</div>
				</div>
				<div className="column">
					<div className="details-container">
						<h4>{project.title}</h4>
						<h5>Location:</h5>
						<p>{project.location}</p>
						<h5>Approximate contract value:</h5>
						<p>{project.cost}</p>
						<h5>Type:</h5>
						<p>{project.type}</p>
						<h5>Approximate size:</h5>
						<p>{project.size}</p>
					</div>
				</div>
			</div>
		</Link>
	</StyledCard>
);

export default ProjectGalleryCard;
