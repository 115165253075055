import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ProjectsPageTemplate from '../templates/projects-template.js';

const copy = {
	description:
		'EK Construction is a general contracting firm offering services including the construction of accessory structures, home additions & interior renovations.',
	masthead: {
		title: 'Projects',
		img: '/img/projects-masthead.jpg',
	},
	gallery: {
		title: 'Featured Projects',
	},
	footer: {
		text: "Let's discuss your next commercial or residential project.",
		cta: {
			text: 'CONTACT US',
			link: '/contact/',
		},
		img: '/img/EK_Home_Bottom.jpg',
	},
};

export const ProjectsQuery = graphql`
	{
		allProjectsJson(filter: { isFeatured: { eq: true } }) {
			nodes {
				title
				category
				galleryImage
				cost
				type
				size
				location
				fields {
					slug
				}
			}
		}
	}
`;

const ProjectsPage = ({ data }) => (
	<Layout>
		<SEO title="Projects" description={copy.description} />
		<ProjectsPageTemplate copy={copy} projectsData={data} />
	</Layout>
);

export default ProjectsPage;
