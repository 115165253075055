import React from 'react';

import Masthead from '../components/masthead';
import Footer from '../components/footer-cta';
import Gallery from '../components/projects/gallery';

const ProjectsPageTemplate = ({ copy, projectsData }) => {
	const { masthead, gallery, footer } = copy;
	return (
		<>
			<Masthead title={masthead.title} img={masthead.img} />
			<Gallery title={gallery.title} data={projectsData} />
			<Footer text={footer.text} cta={footer.cta} img={footer.img} />
		</>
	);
};

export default ProjectsPageTemplate;
