import React from 'react';
import styled from 'styled-components';
import slug from 'slug';

import ProjectGalleryCard from './project-gallery-card';

import topBgImg from '../../../static/img/ConcreteBG_light_top.jpg';
import bottomBgImg from '../../../static/img/ConcreteBG_light_bottom.jpg';

const StyledGallery = styled.section`
	background-color: ${(p) => p.theme.color.snowflake};
	background-image: url(${topBgImg}), url(${bottomBgImg});
	background-size: 100% auto, 100% auto;
	background-position: center top, center bottom;
	background-repeat: no-repeat;

	.gallery-inner {
		padding-top: 3.25rem;

		h2 {
			font-family: ${(p) => p.theme.fonts.nav};
			font-size: 1.4375rem;
			font-weight: 500;
			letter-spacing: 0.05em;
			color: ${(p) => p.theme.color.moreThanAWeek};
			margin-bottom: 2em;
		}

		.categories-container {
			display: flex;
			flex-direction: column;

			.category {
				margin-bottom: 4.25rem;

				@media (max-width: 768px) {
					margin-bottom: 1.25rem;
				}

				&.category-commercial {
					order: 1;
				}

				&.category-residential {
					order: 2;
				}

				&.category-interior-finishing {
					order: 3;
				}

				h3 {
					font-size: 2.0625rem;
					margin-bottom: 0.5em;
					text-transform: capitalize;
					font-weight: 800;
				}
			}
		}
	}
`;

const sortProjectsByCategory = (projects) => {
	const projectsByCategory = [];

	// Get all of the categories from the list of projects
	const unfilteredCategories = projects.map((project, i) => {
		return project.category;
	});

	// Filter the categories down to unique values
	const filteredCategories = unfilteredCategories.filter((category, i) => {
		return unfilteredCategories.indexOf(category) === i;
	});

	// Sort the projects into objects based on category
	filteredCategories.forEach((category) => {
		const categoryObject = {
			name: category,
			items: [],
		};

		projects.forEach((project) => {
			if (project.category === category) {
				categoryObject.items.push(project);
			}
		});

		projectsByCategory.push(categoryObject);
	});

	return projectsByCategory;
};

const Gallery = ({ title, data }) => {
	const { nodes: projects } = data.allProjectsJson;
	const categories = sortProjectsByCategory(projects);

	return (
		<StyledGallery className="section">
			<div className="container">
				<div className="gallery-inner">
					<h2>{title}</h2>
					<div className="categories-container">
						{categories.map((category) => (
							<div
								className={`category category-${slug(category.name, {
									lower: true,
								})}`}
								key={category.name}
							>
								<h3>{category.name}</h3>
								{category.items.map((item) => (
									<ProjectGalleryCard project={item} key={item.title} />
								))}
							</div>
						))}
					</div>
				</div>
			</div>
		</StyledGallery>
	);
};

export default Gallery;
